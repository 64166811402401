'use client'
import * as React from 'react'
import * as Avatar from 'primitives/avatar'
import { tw } from 'utils/classnames'

import { useUserAvatarQuery } from '@ask-cf/hooks/use-user-avatar'

export interface UserAvatarProps extends Avatar.RootProps {
  avatarUrl?: string | null
  initials?: string
}

export function CurrentUserAvatar(props: Avatar.RootProps) {
  const userAvatarQuery = useUserAvatarQuery()
  const userAvatar = userAvatarQuery?.data

  return <UserAvatar {...props} avatarUrl={userAvatar?.avatarUrl} initials={userAvatar?.initials} />
}

export function UserAvatar({ className, avatarUrl, initials, ...props }: UserAvatarProps) {
  return (
    <Avatar.Root {...props} className={tw('aspect-[1] bg-transparent', className)}>
      {avatarUrl ? <Avatar.Image src={avatarUrl} alt="Your avatar" width={20} /> : null}
      <Avatar.Fallback
        className="text-text-primary flex h-full w-full items-center justify-center bg-white"
        delayMs={avatarUrl ? 650 : 0}
      >
        {initials}
      </Avatar.Fallback>
    </Avatar.Root>
  )
}
