import { useUserQuery } from 'codegen/generated/user'

import { userQueryOptions } from './use-user-data'

export function useUserAvatarQuery() {
  return useUserQuery(undefined, {
    ...userQueryOptions,
    select(data) {
      const user = data.me?.user

      const initials =
        [
          user?.firstName && user?.firstName?.charAt(0),
          user?.lastName && user?.lastName?.charAt(0),
        ].join('') || ''

      return {
        initials,
        hasImage: user?.avatarUrl || !initials,
        avatarUrl: user?.avatarUrl,
      }
    },
  })
}
