import { UserQuery, useUserQuery } from 'codegen/generated/user'
import ms from 'ms'
import { useQueryClient } from '@tanstack/react-query'

export type User = NonNullable<NonNullable<UserQuery['me']>['user']>

export const userQueryOptions = {
  keepPreviousData: true,
  staleTime: ms('15s'),
}

// eslint-disable-next-line local/codegen-query-returns
export function useUser() {
  return useUserQuery(undefined, {
    ...userQueryOptions,
    select(data) {
      return data.me?.user
    },
  })
}

export function useUserId() {
  const { data } = useUser()
  return data?.id
}

export function useSetUserQuery() {
  const queryClient = useQueryClient()

  function setUserQuery(user: UserQuery) {
    queryClient.setQueryData<UserQuery>(useUserQuery.getKey(), user)
  }

  return { setUserQuery }
}
