import * as React from 'react'

import CraftGeniusBackground from '@ask-cf/assets/images/user-sidebar-craft-genius.webp'
import CreativeNewbieBackground from '@ask-cf/assets/images/user-sidebar-creative-newbie.webp'
import CuriousCrafterBackground from '@ask-cf/assets/images/user-sidebar-curious-crafter.webp'
import CleverCrafterBackground from '@ask-cf/assets/images/user-sidebar-clever-crafter.webp'
import SkillfulCrafterBackground from '@ask-cf/assets/images/user-sidebar-skillful-crafter.webp'
import MasterMakerBackground from '@ask-cf/assets/images/user-sidebar-master-maker.webp'
import CreativeMentorBackground from '@ask-cf/assets/images/user-sidebar-creative-mentor.webp'
import CraftHeroBackground from '@ask-cf/assets/images/user-sidebar-craft-hero.webp'
import CraftMasterBackground from '@ask-cf/assets/images/user-sidebar-craft-master.webp'
import {
  BadgeCuriousCrafterIcon,
  BadgeCleverCrafterIcon,
  BadgeSkillfulCrafterIcon,
  BadgeMasterMakerIcon,
  BadgeCreativeMentorIcon,
  BadgeCraftHeroIcon,
  BadgeCraftMasterIcon,
  BadgeCraftGeniusIcon,
  IconProps,
} from '@ask-cf/assets/icons'

export interface UserRank {
  translationKey: string
  color: string
  fontColor: string
  backgroundImageUrl: string
  badgeIcon?: React.FC<IconProps>
  coinsReward: number
  backgroundColor: string
  backgroundColorLight: string
}

export enum UserRankType {
  CreativeNewbie = 'CreativeNewbie',
  CuriousCrafter = 'CuriousCrafter',
  CleverCrafter = 'CleverCrafter',
  SkillfulCrafter = 'SkillfulCrafter',
  MasterMaker = 'MasterMaker',
  CreativeMentor = 'CreativeMentor',
  CraftHero = 'CraftHero',
  CraftMaster = 'CraftMaster',
  CraftGenius = 'CraftGenius',
}

export const lastRankType = UserRankType.CraftGenius

export const ranksInOrder = [
  UserRankType.CreativeNewbie,
  UserRankType.CuriousCrafter,
  UserRankType.CleverCrafter,
  UserRankType.SkillfulCrafter,
  UserRankType.MasterMaker,
  UserRankType.CreativeMentor,
  UserRankType.CraftHero,
  UserRankType.CraftMaster,
  UserRankType.CraftGenius,
]

const userRanks: { [key in UserRankType]: UserRank } = {
  [UserRankType.CreativeNewbie]: {
    translationKey: 'creative_newbie',
    color: '#0C3BBE',
    fontColor: '#4A71DE',
    backgroundImageUrl: CreativeNewbieBackground.src,
    coinsReward: 0,
    backgroundColor: '#E3EAFC',
    backgroundColorLight: '#E3EAFC',
  },
  [UserRankType.CuriousCrafter]: {
    translationKey: 'curious_crafter',
    color: '#0C3BBE',
    fontColor: '#4A71DE',
    backgroundImageUrl: CuriousCrafterBackground.src,
    badgeIcon: BadgeCuriousCrafterIcon,
    coinsReward: 100,
    backgroundColor: '#E3EAFC',
    backgroundColorLight: '#E3EAFC',
  },
  [UserRankType.CleverCrafter]: {
    translationKey: 'clever_crafter',
    color: '#0C3BBE',
    fontColor: '#4A71DE',
    backgroundImageUrl: CleverCrafterBackground.src,
    badgeIcon: BadgeCleverCrafterIcon,
    coinsReward: 200,
    backgroundColor: '#E3EAFC',
    backgroundColorLight: '#E3EAFC',
  },
  [UserRankType.SkillfulCrafter]: {
    translationKey: 'skillful_crafter',
    color: '#0C3BBE',
    fontColor: '#4A71DE',
    backgroundImageUrl: SkillfulCrafterBackground.src,
    badgeIcon: BadgeSkillfulCrafterIcon,
    coinsReward: 1000,
    backgroundColor: '#E3EAFC',
    backgroundColorLight: '#E3EAFC',
  },
  [UserRankType.MasterMaker]: {
    translationKey: 'master_maker',
    color: '#3BCBB8',
    fontColor: '#317C73',
    backgroundImageUrl: MasterMakerBackground.src,
    badgeIcon: BadgeMasterMakerIcon,
    coinsReward: 3500,
    backgroundColor: '#D6EBE4',
    backgroundColorLight: '#E1FEFA',
  },
  [UserRankType.CreativeMentor]: {
    translationKey: 'creative_mentor',
    color: '#3BCBB8',
    fontColor: '#317C73',
    backgroundImageUrl: CreativeMentorBackground.src,
    badgeIcon: BadgeCreativeMentorIcon,
    coinsReward: 17500,
    backgroundColor: '#D6EBE4',
    backgroundColorLight: '#E1FEFA',
  },
  [UserRankType.CraftHero]: {
    translationKey: 'craft_hero',
    color: '#FF768A',
    fontColor: '#FF2445',
    backgroundImageUrl: CraftHeroBackground.src,
    badgeIcon: BadgeCraftHeroIcon,
    coinsReward: 35000,
    backgroundColor: '#FCE7EA',
    backgroundColorLight: '#FAE6E9',
  },
  [UserRankType.CraftMaster]: {
    translationKey: 'craft_master',
    color: '#FF768A',
    fontColor: '#FF2445',
    backgroundImageUrl: CraftMasterBackground.src,
    badgeIcon: BadgeCraftMasterIcon,
    coinsReward: 175000,
    backgroundColor: '#FCE7EA',
    backgroundColorLight: '#FAE6E9',
  },
  [UserRankType.CraftGenius]: {
    translationKey: 'craft_genius',
    color: '#FF768A',
    fontColor: '#FF2445',
    backgroundImageUrl: CraftGeniusBackground.src,
    badgeIcon: BadgeCraftGeniusIcon,
    coinsReward: 300000,
    backgroundColor: '#FCE7EA',
    backgroundColorLight: '#FAE6E9',
  },
}

export { userRanks }
