import lodashMerge from 'lodash.merge'

import { tw } from './classnames'

/**
 * `propped` is a function that lets you set default props on a component.
 * For a more advanced case, you might just want to return a function
 * and return the component.
 *
 * Takes a component and returns a new component that merges the props passed to
 * the new component with the props passed to the original component.
 *
 * You can customise the merge function if necessary. By default, it uses
 * `lodash.merge`.
 */
export function propped<C extends React.ElementType>(
  Component: C,
  baseProps: React.ComponentProps<C>,
  merge = lodashMerge,
) {
  function proppedInner({ children, ...nextProps }: React.ComponentProps<C>) {
    const props: React.ComponentProps<C> = merge({}, baseProps, nextProps)

    // we want to merge classNames in a slightly different way than any other
    // props since we want to combine them, and also combine them with `tw`
    // to allow for tailwind classes to be passed in and overridden/merged.
    if (baseProps?.className || nextProps?.className) {
      props.className = tw(baseProps?.className, nextProps?.className)
    }

    // the merge was also messing with children, so let's skip merging children
    props.children = children

    return <Component {...props} />
  }

  // wrap in try/catch to let nextjs fail to read `import.meta.env.DEV`
  try {
    // better dev tools experience
    if (
      (process.env.NODE_ENV === 'development' || process.env.DEV) &&
      typeof Component === 'string'
    ) {
      proppedInner.displayName = `propped(${Component})`
    }
  } catch {}

  return proppedInner
}
